<template>
  <v-card class="upload-card fill-height" flat>
    <v-dialog fullscreen v-model="dialog">
      <v-card v-if="selectedLog">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Getätigte Änderungen</v-toolbar-title>
          <v-spacer />
          <v-btn icon dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-layout>
            <v-flex xs6>
              <h2>Änderungen</h2>
            </v-flex>
          </v-layout>

          <v-layout mb-4>
            <v-flex xs2 />
            <v-flex class="grey lighten-2" xs8>
              <pre>{{ JSON.stringify(selectedLog.changed_data, null, 2) }}</pre>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card-title>
      <h3>Logs</h3>
    </v-card-title>

    <v-data-table
      :disable-initial-sort="true"
      :hide-actions="pagination.totalItems === 0"
      :headers="headers"
      :items="logs"
      :pagination.sync="pagination"
      :rows-per-page-items="[15]"
      :total-items="pagination.totalItems || 0"
      :loading="loading"
    >
      <template v-slot:items="props">
        <tr @click="showLog(props.item.id)" style="cursor: pointer">
          <td>{{ props.item.context.type_of_change }}</td>
          <td>{{ props.item.message }}</td>
          <td>
            {{ props.item.user ? props.item.user.username : '' }}
          </td>
          <td>
            {{ formatDate(props.item.time, 'DD.MM.YYYY (HH:mm [Uhr])') }}
          </td>
        </tr>
      </template>
      <template slot="pageText" slot-scope="props">
        Einträge {{ props.pageStart }} bis {{ props.pageStop }} von insgesamt
        {{ props.itemsLength }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import serviceApi from '@/api/serviceApi'
import formatDate from 'date-fns/format'
import * as Sentry from '@sentry/vue'

export default {
  name: 'Logs',
  components: {},
  data() {
    return {
      loading: false,
      dialog: false,
      selectedLogId: null,
      pagination: {
        page: 1,
        rowsPerPage: 20,
        totalItems: 0,
      },
      headers: [
        { text: 'Typ', value: 'type', sortable: false },
        { text: 'Beschreibung', value: 'message', sortable: false },
        { text: 'Benutzer', value: 'context.user_id', sortable: false },
        { text: 'Datum', value: 'time.date', sortable: false },
      ],
      logs: [],
      updateInterval: null,
    }
  },
  methods: {
    showLog(id) {
      this.dialog = true
      this.selectedLogId = id
    },
    async updateLogs() {},
    async getLogs(page) {
      if (!this.loading) {
        try {
          this.loading = true
          const result = await serviceApi.getLogs(page)
          this.logs = result.data
          this.pagination.totalItems = result.total
          this.pagination.page = result.current_page
          this.pagination.rowsPerPage = result.per_page
        } catch (e) {
          Sentry.captureException(e)
        } finally {
          this.loading = false
        }
      }
    },
    formatDate,
  },
  computed: {
    selectedLog() {
      const log = this.logs.find((elem) => elem.id === this.selectedLogId)
      return log ? log.context : null
    },
  },
  watch: {
    'pagination.page': {
      handler: 'getLogs',
      immediate: true,
    },
  },
  created() {},
}
</script>

<style>
pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>
